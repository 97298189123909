import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColumActive = _resolveComponent("ColumActive")
  const _component_DefaultTable = _resolveComponent("DefaultTable")
  const _component_ListCopyEditDel = _resolveComponent("ListCopyEditDel")
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode(_component_DefaultTable, _mergeProps(_ctx.paramsTable, { onAdd: _ctx.onAdd }), {
      "col-IsActive": _withCtx(({ rowData }) => [
        _createVNode(_component_ColumActive, {
          valueInput: rowData.IsActive,
          idData: rowData.Id,
          endpoint: _ctx.endpoint,
          customFilter: _ctx.customFilter
        }, null, 8, ["valueInput", "idData", "endpoint", "customFilter"])
      ]),
      "col-Action": _withCtx(({ rowData }) => [
        (_ctx.route.meta?.permission?.update)
          ? (_openBlock(), _createBlock("i", {
              key: 0,
              class: "pi pi-ellipsis-h text-base cursor-pointer text-black",
              "aria:haspopup": "true",
              onClick: ($event: any) => (_ctx.showDropDown($event, rowData))
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 16, ["onAdd"]),
    _createVNode(_component_OverlayPanel, { ref: "op" }, {
      default: _withCtx(() => [
        _createVNode(_component_ListCopyEditDel, {
          edit: _ctx.route.meta?.permission?.update,
          onEdit: _ctx.onEditData
        }, null, 8, ["edit", "onEdit"])
      ]),
      _: 1
    }, 512)
  ]))
}